import React from 'react'
import { motion } from 'framer-motion'
import { ArrowRight } from '../../assets/svg'
import { projects } from '../../data'
import './styles.css'

const Projects = () => {
  return (
    <section
      id="projects"
      className="projects-container"
    >
      <div className="projects">
        <motion.header
          className="section-header"
          initial={{ y: 40, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
        >
          <span className="header__subtitle">Check Out My</span>
          <h2 className="header__title">Selected Projects</h2>
        </motion.header>

        <div className="projects__grid">
          {projects.map((proj) => (
            <ProjectItem
              key={proj.id}
              project={proj}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

const ProjectItem = ({ project }) => {
  return (
    <motion.article
      className="project"
      initial={{ y: 40, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
    >
      <img
        src={`/assets/${project.img}`}
        alt={project.title}
        className="project__img"
      />

      <div className={project.darkLetters ? 'project__info project__info--dark' : 'project__info'}>
        <span>{project.year}</span>
        <h3>{project.title}</h3>
      </div>

      <div className="project__overlay">
        <div className="overlay__info">
          <span>{project.year}</span>
          <h3>{project.title}</h3>
          <p>{project.description}</p>
          <div className="info__links">
            {project.website && (
              <a
                href={project.website}
                target="_blank"
                className="link-item"
                rel="noreferrer"
              >
                <span>Visit Website</span>
                <ArrowRight className="link-item__icon" />
              </a>
            )}
            <a
              href={project.github}
              target="_blank"
              className="link-item"
              rel="noreferrer"
            >
              <span>View Project</span>
              <ArrowRight className="link-item__icon" />
            </a>
          </div>
        </div>
      </div>
    </motion.article>
  )
}

export default Projects
