import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import { motion } from 'framer-motion'
import { Whatsapp, Linkedin, Mail, Warning, Success } from '../../assets/svg'
import './styles.css'

const Contact = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    name: '',
    email: '',
    message: '',
    global: ''
  })
  const [success, setSuccess] = useState(false)

  const form = useRef()

  const resetError = () => setError({ name: '', email: '', message: '', global: '' })

  const sendEmail = (e) => {
    e.preventDefault()
    setLoading(true)

    const name = form.current.name.value
    const email = form.current.email.value
    const message = form.current.message.value

    if (!name || !email || !message) {
      setError({
        name: !name ? 'Please enter your name' : '',
        email: !email ? 'Please enter your email' : '',
        message: !message ? 'Please enter a message' : '',
        global: 'Check that you got all the fields right!'
      })
      setLoading(false)
      setTimeout(() => resetError(), 3000)
      return
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then((result) => {
        setSuccess(true)
        setTimeout(() => setSuccess(false), 3000)
      })
      .catch((err) => {
        setError({ ...error, global: 'Something went wrong, try again!' })
        console.log(err)
        setTimeout(() => resetError(), 3000)
      })
      .finally(() => {
        setLoading(false)
        form.current.reset()
      })
  }

  return (
    <section
      id="contact"
      className="contact-container"
    >
      <div className="contact">
        <motion.header
          className="section-header"
          initial={{ y: 40, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
        >
          <span className="header__subtitle">Contact</span>
          <h2 className="header__title">Get in Touch</h2>
        </motion.header>

        <motion.p
          className="contact__text"
          initial={{ y: 40, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
        >
          Are you in search of a skilled fullstack developer to join your team? Feel free to reach out to me via
          WhatsApp, LinkedIn, or email, and I'll be more than happy to connect with you. Let's discuss how I can
          contribute to your projects and make a valuable impact.
        </motion.p>

        <motion.div
          className="contact__grid"
          initial={{ y: 40, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
        >
          <div className="contact-grid__cards">
            <a
              href="https://wa.me/+59896782661"
              target="_blank"
              rel="noreferrer"
              className="grid-cards__item whatsapp"
            >
              <div className="cards-item__text">
                <span className="cards-item__subtitle">WhatsApp</span>
                <h3 className="cards-item__title">Send me a message</h3>
              </div>

              <span className="cards-item__account-contact">+598 96 782 661</span>
              <Whatsapp className="cards-item__icon-contact" />
            </a>

            <a
              href="https://www.linkedin.com/in/mathias-ramilo/"
              target="_blank"
              className="grid-cards__item linkedin"
              rel="noreferrer"
            >
              <div className="cards-item__text">
                <span className="cards-item__subtitle">Linkedin</span>
                <h3 className="cards-item__title">Contact Me</h3>
              </div>

              <span className="cards-item__account-contact">Mathias Ramilo</span>
              <Linkedin className="cards-item__icon-contact" />
            </a>
          </div>

          <div className="contact-grid__form">
            <form
              ref={form}
              onSubmit={sendEmail}
            >
              <header className="form__header">
                <span className="form-header__subtitle">Email</span>
                <h3 className="form-header__title">Send me an Email</h3>
              </header>

              <div className="form__input-group">
                <div className="form__input-field">
                  <label
                    htmlFor="name"
                    className="input-group__label"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className={error.name ? 'input-group__input error' : 'input-group__input'}
                    placeholder="What's your name?"
                  />
                </div>

                <div className="form__input-field">
                  <label
                    htmlFor="email"
                    className="input-group__label"
                  >
                    Your Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className={error.email ? 'input-group__input error' : 'input-group__input'}
                    placeholder="What's your email address?"
                  />
                </div>

                <div className="form__input-field">
                  <label
                    htmlFor="message"
                    className="input-group__label"
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    className={error.message ? 'input-group__input error' : 'input-group__input'}
                    placeholder="What's your message?"
                  />
                </div>
              </div>

              <div className="form__submit-container">
                <div className={error.global || loading || success ? 'form__info show' : 'form__info'}>
                  {error.global ? (
                    <>
                      <Warning className="form-info__icon error" />
                      <span className="form-info__message error">{error.global}</span>
                    </>
                  ) : loading ? (
                    <>
                      <span className="form-info__loader"></span>
                      <span className="form-info__message loading">Sending your message</span>
                    </>
                  ) : success ? (
                    <>
                      <Success className="form-info__icon success" />
                      <span className="form-info__message success">Your message was successfully sent!</span>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>

                <button
                  type="submit"
                  className="form__submit-btn"
                  disabled={loading}
                >
                  <Mail className="submit-btn__icon" />
                  <span>Send Email</span>
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default Contact
