import React, { useEffect, useState } from 'react'
import { Loader, Navbar, Banner, Projects, AboutMe, Skills, Contact, Footer, ScrollTop } from './components'

const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1600)
  }, [])

  return (
    <div className="body">
      <Loader open={loading} />
      <Navbar />
      <Banner />
      {!loading && (
        <>
          <Projects />
          <AboutMe />
          <Skills />
          <Contact />
          <Footer />
          <ScrollTop />
        </>
      )}
    </div>
  )
}

export default App
