export const projects = [
  {
    id: 0,
    title: 'FingWatch - Movie Search and Recommendation Web App',
    description:
      'A movie platform enhanced by the Gorse recommender system service and integrated with the Typesense search engine. This dynamic system consistently refreshes its database with the most recent movies sourced from the TMDB API, ensuring users receive personalized recommendations aligned with their preferences.',
    year: '2023',
    img: 'fingwatch-mockup.jpg',
    website: '',
    github: 'https://github.com/mathiramilo/fing-watch',
    darkLetters: false
  },
  {
    id: 1,
    title: 'Foodie - React Native Food Order App',
    description:
      'Mobile food order app developed with React Native and Expo for the final project of Coderhouse mobile development course.',
    year: '2023',
    img: 'foodie-mockup.jpg',
    website: '',
    github: 'https://github.com/mathiramilo/foodie',
    darkLetters: false
  },
  {
    id: 2,
    title: 'TodoApp - React Native Application',
    description: 'React Native Todo App for the Challenges of Coderhouse Mobile Development Course.',
    year: '2023',
    img: 'todoapp-mockup.jpg',
    website: '',
    github: 'https://github.com/mathiramilo/chmdc-todo-app',
    darkLetters: false
  },
  {
    id: 3,
    title: 'CHBP - MERN Stack eCommerce',
    description:
      'Footwear e-commerce developed with React, Node JS, Express and MongoDB for the final project of the Coderhouse backend development course.',
    year: '2022 - 2023',
    img: 'chbp-mockup.jpg',
    website: 'https://chbp.vercel.app',
    github: 'https://github.com/mathiramilo/chbp-frontend',
    darkLetters: false
  },
  {
    id: 4,
    title: 'Meat Palace - React and Firebase eCommerce',
    description:
      'E-commerce developed with React JS, Typescript and Firebase BaaS where you can buy meats of diferent types and qualities as well as other products for an amazing barbacue.',
    year: '2022',
    img: 'meatpalace-mockup.jpg',
    website: 'https://meatpalace-ramilo.web.app',
    github: 'https://github.com/mathiramilo/meat-palace',
    darkLetters: true
  },
  {
    id: 5,
    title: 'Fit Teammate - JavaScript Web App / eCommerce',
    description:
      'E-commerce/Web App developed with vanilla JavaScript dedicated to fitness where the user enters their data and calculates their BMI (Body Mass Index), gives advice or tips on diet and exercise and also builds a personalized routine for you. On the other hand, it has an Ecommerce where you can find a wide variety of products for fitness.',
    year: '2022',
    img: 'fitteammate-mockup.jpg',
    website: 'https://mathiramilo.github.io/fit-teammate',
    github: 'https://github.com/mathiramilo/fit-teammate',
    darkLetters: false
  },
  {
    id: 6,
    title: 'Car House - HTML and SCSS Static Website',
    description:
      'HTML and SCSS static website for buying and selling vehicles. In it you can search among a large number of used vehicles published by other users as well as publish your vehicle for sale. You can also search for vehicles 0 km offered by the wide variety of dealerships found here. If you have a dealership you can register it on the site as well.',
    year: '2021 - 2022',
    img: 'carhouse-mockup.jpg',
    website: 'https://carhouse.netlify.app',
    github: 'https://github.com/mathiramilo/carhouse',
    darkLetters: false
  }
]
