import React, { useEffect, useState } from 'react'
import { ArrowTop } from '../../assets/svg'
import './styles.css'

const ScrollTop = () => {
  const [visible, setVisible] = useState(false)

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <button className={visible ? 'scroll-top show' : 'scroll-top'} onClick={scrollToTop}>
      <ArrowTop className="scroll-top__icon" />
    </button>
  )
}

export default ScrollTop
