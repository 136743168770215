import React from 'react'
import './styles.css'

const Loader = ({ open }) => {
  return (
    <section className={open ? 'loader-container' : 'loader-container closed'}>
      <div className="loader">
        <header className="navbar-container navbar-container--loader">
          <div className="navbar">
            <div className="navbar__left-item">
              <span className="left-item__title left-item__title--loader">Mathias Ramilo</span>
              <span className="left-item__subtitle left-item__subtitle--loader">Fullstack Developer</span>
            </div>
          </div>
        </header>

        <div className="loader__icon"></div>

        <div className="loader__message">
          <span className="message__subtitle">Welcome</span>
          <span className="message__title">Wait a Bit</span>
        </div>
      </div>
    </section>
  )
}

export default Loader
