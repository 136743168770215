import React from 'react'
import './styles.css'

const Footer = () => {
  return (
    <section className="footer-container">
      <footer className="footer">
        <div className="footer__grid">
          <div className="footer-grid__item">
            <h3>Contact information -</h3>
            <p>
              Feel free to reach out to me at any time. You can contact me via WhatsApp, LinkedIn, or email—whichever
              method you prefer.
            </p>
            <div className="footer-grid-item__data">
              <div>
                <span>E: </span>
                <span>mathiramilo2290@gmail.com</span>
              </div>
              <div>
                <span>P: </span>
                <span>+598 96 782 661</span>
              </div>
            </div>
          </div>
          <div className="footer-grid__item">
            <h3>Latest projects -</h3>
            <ul className="footer-grid-item__list">
              <li>
                <a
                  href="https://github.com/mathiramilo/fing-watch"
                  target="_blank"
                  rel="noreferrer"
                >
                  FingWatch
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/mathiramilo/foodie"
                  target="_blank"
                  rel="noreferrer"
                >
                  Foodie
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/mathiramilo/chmdc-todo-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  TodoApp
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/mathiramilo/chbp-frontend"
                  target="_blank"
                  rel="noreferrer"
                >
                  CHBP Footwear
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/mathiramilo/meat-palace"
                  target="_blank"
                  rel="noreferrer"
                >
                  Meat Palace
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/mathiramilo/fit-teammate"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fit Teammate
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/mathiramilo"
                  target="_blank"
                  rel="noreferrer"
                >
                  All Projects
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-grid__item">
            <h3>Current availability -</h3>
            <p>
              While currently studying software engineering and actively working on various projects, I am always open
              to exploring new opportunities. Let's talk!
            </p>
          </div>
          <div className="footer-grid__item">
            <h3>Follow me on -</h3>
            <ul className="footer-grid-item__list">
              <li>
                <a
                  href="https://www.linkedin.com/in/mathias-ramilo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linkedin
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/mathiramilo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/mathi_ramilo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer__brand">
          <span className="left-item__title">Mathias Ramilo</span>
          <span className="left-item__subtitle">Fullstack Developer</span>
        </div>

        <div className="footer__copyright">© 2023 - Mathias Ramilo</div>
      </footer>
    </section>
  )
}

export default Footer
