import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Linkedin, Github, Instagram } from '../../assets/svg'
import './styles.css'

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isDrawerClosing, setIsDrawerClosing] = useState(false)

  const handleCloseDrawer = () => {
    setIsDrawerClosing(true)
    setIsDrawerOpen(false)
    setTimeout(() => {
      setIsDrawerClosing(false)
    }, 400)
  }

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isDrawerOpen])

  return (
    <>
      <header className="navbar-container">
        <div className="navbar">
          <div className="navbar__left-item">
            <span className="left-item__title">Mathias Ramilo</span>
            <span className="left-item__subtitle">Fullstack Developer</span>
          </div>
          <button className="navbar__right-item" onClick={() => setIsDrawerOpen(true)}>
            <span className="right-item__title">Menu</span>
            <i className={isDrawerOpen ? 'right-item__icon open' : 'right-item__icon'}>
              <span></span>
              <span></span>
              <span></span>
            </i>
          </button>
        </div>
      </header>

      <section
        className={
          isDrawerClosing ? 'drawer-container closing' : isDrawerOpen ? 'drawer-container open' : 'drawer-container'
        }
      >
        <div className="drawer">
          <header className="navbar-container">
            <div className="navbar">
              <div className="navbar__left-item">
                <span className="left-item__title left-item__title--drawer">Mathias Ramilo</span>
                <span className="left-item__subtitle left-item__subtitle--drawer">Fullstack Developer</span>
              </div>
              <button className="navbar__right-item" onClick={handleCloseDrawer}>
                <span className="right-item__title right-item__title--drawer">Close</span>
                <i className="right-item__icon right-item__icon--drawer">
                  <span></span>
                  <span></span>
                </i>
              </button>
            </div>
          </header>

          <div className="drawer__menu">
            <motion.span
              className="menu__title"
              variants={titleVariants}
              initial="closed"
              animate={isDrawerClosing ? 'closing' : isDrawerOpen ? 'open' : 'closed'}
            >
              Menu
            </motion.span>

            <motion.nav
              className="menu__list"
              variants={listVariants}
              initial="closed"
              animate={isDrawerClosing ? 'closing' : isDrawerOpen ? 'open' : 'closed'}
            >
              <motion.a
                href="#projects"
                className="list__item"
                onClick={handleCloseDrawer}
                initial={{ opacity: 0 }}
                animate={isDrawerOpen ? { opacity: 1, transition: { duration: 0.2, delay: 0.16 } } : { opacity: 0 }}
              >
                Projects
              </motion.a>
              <motion.a
                href="#about"
                className="list__item"
                onClick={handleCloseDrawer}
                initial={{ opacity: 0 }}
                animate={isDrawerOpen ? { opacity: 1, transition: { duration: 0.2, delay: 0.32 } } : { opacity: 0 }}
              >
                About Me
              </motion.a>
              <motion.a
                href="#skills"
                className="list__item"
                onClick={handleCloseDrawer}
                initial={{ opacity: 0 }}
                animate={isDrawerOpen ? { opacity: 1, transition: { duration: 0.2, delay: 0.48 } } : { opacity: 0 }}
              >
                Skills
              </motion.a>
              <motion.a
                href="#contact"
                className="list__item"
                onClick={handleCloseDrawer}
                initial={{ opacity: 0 }}
                animate={isDrawerOpen ? { opacity: 1, transition: { duration: 0.2, delay: 0.64 } } : { opacity: 0 }}
              >
                Contact
              </motion.a>
            </motion.nav>

            <motion.div
              className="menu__social"
              variants={socialVariants}
              initial="closed"
              animate={isDrawerClosing ? 'closing' : isDrawerOpen ? 'open' : 'closed'}
            >
              <a
                href="https://www.linkedin.com/in/mathias-ramilo"
                className="social__item"
                target="_blank"
                rel="noreferrer"
              >
                <Linkedin className="item__icon" />
              </a>
              <a href="https://github.com/mathiramilo" className="social__item" target="_blank" rel="noreferrer">
                <Github className="item__icon" />
              </a>
              <a
                href="https://www.instagram.com/mathi_ramilo"
                className="social__item"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram className="item__icon" />
              </a>
            </motion.div>
          </div>

          <footer className="drawer-footer">
            <motion.span
              className="footer__text"
              variants={footerVariants}
              initial="closed"
              animate={isDrawerClosing ? 'closing' : isDrawerOpen ? 'open' : 'closed'}
            >
              © 2023 Mathias Ramilo
            </motion.span>
          </footer>
        </div>
      </section>
    </>
  )
}

const listVariants = {
  closed: {
    opacity: 0
  },
  open: {
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.8,
      delay: 0.4
    }
  },
  closing: {
    opacity: 0
  }
}

const titleVariants = {
  closed: {
    opacity: 0
  },
  open: {
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.8,
      delay: 0.4
    }
  },
  closing: {
    opacity: 0
  }
}

const socialVariants = {
  closed: {
    opacity: 0,
    y: 50
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.8,
      delay: 0.4
    }
  },
  closing: {
    opacity: 0,
    y: 0
  }
}

const footerVariants = {
  closed: {
    opacity: 0,
    y: 50
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 1,
      delay: 0.5
    }
  },
  closing: {
    opacity: 0,
    y: 0
  }
}

export default Navbar
