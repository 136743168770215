import React from 'react'
import { motion } from 'framer-motion'
import {
  NextJS,
  React as ReactIcon,
  JavaScript,
  Html,
  Css,
  Tailwind,
  NodeJS,
  Express,
  MongoDB,
  Firebase,
  Jwt,
  Python,
  Aws
} from '../../assets/svg'
import './styles.css'

const Skills = () => {
  return (
    <section
      id="skills"
      className="skills-container"
    >
      <div className="skills">
        <motion.header
          className="section-header"
          initial={{ y: 40, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
        >
          <span className="header__subtitle">Look At My</span>
          <h2 className="header__title">Development Skills</h2>
        </motion.header>

        <div className="skills__grid">
          <FrontEndSkills />
          <BackEndSkills />
        </div>
      </div>
    </section>
  )
}

const FrontEndSkills = () => {
  return (
    <motion.div
      className="skills__group"
      initial={{ y: 40, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
    >
      <div className="skills-group__text">
        <span>Frontend</span>
        <h3>User Interface Development</h3>
      </div>

      <div className="skills-group__grid">
        <div className="skills__item nextjs">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Framework</span>
            <h3>Next JS</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <NextJS className="skills-item__icon" />
        </div>

        <div className="skills__item react">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Framework</span>
            <h3>React</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <ReactIcon className="skills-item__icon" />
        </div>

        <div className="skills__item react-native">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Framework</span>
            <h3>React Native</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <ReactIcon className="skills-item__icon" />
        </div>

        <div className="skills__item javascript">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Language</span>
            <h3>JavaScript</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <JavaScript className="skills-item__icon" />
        </div>

        <div className="skills__item html">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Markup</span>
            <h3>HTML</h3>
          </div>

          <span className="skills-item__level">Expert</span>

          <Html className="skills-item__icon" />
        </div>

        <div className="skills__item css">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Stylesheet</span>
            <h3>CSS</h3>
          </div>

          <span className="skills-item__level">Expert</span>

          <Css className="skills-item__icon" />
        </div>

        <div className="skills__item tailwind">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Framework</span>
            <h3>Tailwind</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <Tailwind className="skills-item__icon" />
        </div>
      </div>
    </motion.div>
  )
}

const BackEndSkills = () => {
  return (
    <motion.div
      className="skills__group"
      initial={{ y: 40, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
    >
      <div className="skills-group__text">
        <span>Backend</span>
        <h3>Server & APIs Development</h3>
      </div>

      <div className="skills-group__grid">
        <div className="skills__item nodejs">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Language</span>
            <h3>Node JS</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <NodeJS className="skills-item__icon" />
        </div>

        <div className="skills__item express">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Framework</span>
            <h3>Express</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <Express className="skills-item__icon" />
        </div>

        <div className="skills__item firebase">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>BAAS</span>
            <h3>Firebase</h3>
          </div>

          <span className="skills-item__level">Intermediate</span>

          <Firebase className="skills-item__icon" />
        </div>

        <div className="skills__item mongodb">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Database</span>
            <h3>MongoDB</h3>
          </div>

          <span className="skills-item__level">Advanced</span>

          <MongoDB className="skills-item__icon" />
        </div>

        <div className="skills__item python">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Language</span>
            <h3>Python</h3>
          </div>

          <span className="skills-item__level">Intermediate</span>

          <Python className="skills-item__icon" />
        </div>

        <div className="skills__item jwt">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Authentication</span>
            <h3>JWT</h3>
          </div>

          <span className="skills-item__level">Intermediate</span>

          <Jwt className="skills-item__icon" />
        </div>

        <div className="skills__item aws">
          <div className="skills-item__gradient"></div>

          <div className="skills-item__text">
            <span>Cloud</span>
            <h3>Amazon AWS</h3>
          </div>

          <span className="skills-item__level">Basic</span>

          <Aws className="skills-item__icon" />
        </div>
      </div>
    </motion.div>
  )
}

export default Skills
