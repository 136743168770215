import React from 'react'
import { motion } from 'framer-motion'
import MeImg from '../../assets/img/me.png'
import './styles.css'

const Banner = () => {
  return (
    <section className="container banner-container">
      <div className="banner">
        <motion.div
          className="banner__background-text"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, delay: 1.2 }}
        >
          <span>Fullstack Developer</span>
        </motion.div>
        <div className="banner__content">
          <motion.span
            className="content__subtitle"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 2, ease: 'easeOut' }}
          >
            Mathias Ramilo
          </motion.span>
          <motion.h1
            className="content__title"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 2.2, ease: 'easeOut' }}
          >
            Fullstack Developer
          </motion.h1>
          <motion.p
            className="content__text"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 2.4, ease: 'easeOut' }}
          >
            Over the past four years, I have dedicated my time as both a software engineering student and a self-taught
            individual, working in teams on various projects and developing multiple personal initiatives for my
            academic studies and personal growth. I am excited to collaborate on ambitious projects and be part of
            high-performing teams. I always strive to exceed expectations and deliver high-quality results.
          </motion.p>
          <motion.div
            className="content__buttons"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 2.6, ease: 'easeOut' }}
          >
            <a href="#projects" className="buttons__item">
              View Projects
            </a>
            <span className="buttons__separator">or</span>
            <a href="#contact" className="buttons__item">
              Contact Me
            </a>
          </motion.div>
        </div>
        <motion.div
          className="banner__image"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.8, delay: 2, ease: 'easeOut' }}
        >
          <img src={MeImg} alt="Me" />
        </motion.div>
        <a href="#projects" className="banner__scroll-down">
          <div></div>
        </a>
      </div>
    </section>
  )
}

export default Banner
