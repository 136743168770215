import React from 'react'
import { motion } from 'framer-motion'
import { Github, Linkedin, Instagram } from '../../assets/svg'
import './styles.css'

const AboutMe = () => {
  return (
    <section id="about" className="about-container">
      <div className="about">
        <motion.header
          className="section-header"
          initial={{ y: 40, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
        >
          <span className="header__subtitle">Learn More</span>
          <h2 className="header__title">About Me</h2>
        </motion.header>

        <motion.div
          className="about__text"
          initial={{ y: 40, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
        >
          <span>Hi, I'm Mathias Ramilo.</span>
          <p>
            Over the past four years, I have dedicated my time as both a software engineering student and a self-taught
            individual, working in teams on various projects and developing multiple personal initiatives for my
            academic studies and personal growth. My passion for software development has led me to acquire solid
            knowledge in multiple programming languages, frameworks, databases, and cloud computing services, along with
            experience in web and mobile application development.
          </p>
          <p>
            I thrive on staying updated with the latest trends and advancements in the field of software engineering. I
            am constantly seeking opportunities to learn and grow professionally, and I am motivated by the challenges
            that this dynamic and ever-evolving industry presents. I am excited to collaborate on ambitious projects and
            be part of high-performing teams. I always strive to exceed expectations and deliver high-quality results.
          </p>
          <p>
            Currently, I am pursuing a degree in Software Engineering at the{' '}
            <a href="https://www.fing.edu.uy/" target="_blank" rel="noreferrer">
              Faculty of Engineering, UDELAR
            </a>
            . My academic background has provided me with a solid foundation in programming and software engineering
            fundamentals. Additionally, I have complemented my formal education with a career at{' '}
            <a href="https://coderhouse.com.uy/" target="_blank" rel="noreferrer">
              Coderhouse
            </a>
            , where I obtained the title of Web/Mobile MERN Full Stack Developer. During this intensive program, I
            acquired practical skills in web and mobile application development.
          </p>
          <p>
            Please feel free to reach out to me if you are seeking a committed software developer with strong technical
            skills and a success-oriented mindset.
          </p>
        </motion.div>

        <div className="about__cards">
          <motion.a
            href="https://github.com/mathiramilo"
            className="cards__item"
            target="_blank"
            rel="noreferrer"
            initial={{ y: 40, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
          >
            <div className="cards__item--github">
              <div className="cards-item__content">
                <span>Github</span>
                <h3>Projects - See all my projects</h3>
              </div>
              <Github className="cards-item__icon" />
              <span className="cards-item__account">@mathiramilo</span>
            </div>
          </motion.a>

          <motion.a
            href="https://www.linkedin.com/in/mathias-ramilo"
            className="cards__item cards__item--medium"
            target="_blank"
            rel="noreferrer"
            initial={{ y: 40, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
          >
            <div className="cards__item--linkedin">
              <div className="cards-item__content">
                <span>Linkedin</span>
                <h3>Work - Interested? Send me a message</h3>
              </div>
              <Linkedin className="cards-item__icon" />
              <span className="cards-item__account">Mathias Ramilo</span>
            </div>
          </motion.a>

          <motion.a
            href="https://www.instagram.com/mathi_ramilo"
            className="cards__item"
            target="_blank"
            rel="noreferrer"
            initial={{ y: 40, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ type: 'spring', bounce: 0, duration: 1.6, ease: 'easeOut', delay: 0.1 }}
          >
            <div className="cards__item--instagram">
              <div className="cards-item__content">
                <span>Instagram</span>
                <h3>Personal Life - Learn more about me</h3>
              </div>
              <Instagram className="cards-item__icon" />
              <span className="cards-item__account">@mathi_ramilo</span>
            </div>
          </motion.a>
        </div>
      </div>
    </section>
  )
}

export default AboutMe
